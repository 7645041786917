<template>
	<b-card class="checkboxWrapper">
		<VendorUserAssignModal :id="popupUserId" :user="popupUser" />
		<b-table
				responsive
				bordered
				class="products-table shadow-sm rounded"
				:items="admins"
				show-empty
				empty-text="No matching records found"
				:fields="fields"
				hover
				small
				@row-clicked="onRowClick"
		/>
	</b-card>
</template>

<script>
import {
	BTable,
	BCard
} from "bootstrap-vue";
import VendorUserAssignModal from "@/views/role-management/components/VendorUserAssignModal.vue";
import { mapActions, mapState } from "vuex";

export default {
	components: {
		BTable,
		BCard,
		VendorUserAssignModal
	},
	data() {
		return {
			popupUser: null,
			popupUserId: null,
			fields: [
				{
					key: "id",
					label: "Id",
				},
				{
					key: "userName",
					label: "User Name",
				},
				{
					key: "email",
					label: "Email",
				},
				{
					key: "mobile",
					label: "Mobile Number",
				},
			],
		};
	},
	computed: {
		...mapState("roleManagement", ["userRoles", "userBranches", "admins"]),
	},
	created() {
		this.getAdmins()
				.then(() => {
					console.log(this.admins)
				})
	},
	methods: {
		...mapActions("roleManagement", [
			"getAdmins",
			"getUserBranches"
		]),
		onRowClick(user) {
			this.getUserBranches(user.id)
					.then(() => {
						this.popupUserId = user.id;
						this.popupUser = this.userBranches;
						this.$bvModal.show('vendor-user-modal');
					})
		},
	},
};
</script>
